<template>
  <div class="min-h-screen pt-16 pb-12 flex flex-col bg-white">
    <main
      class="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8"
    >
      <div class="flex-shrink-0 flex justify-center">
        <a href="/" class="inline-flex">
          <span class="sr-only">Workflow</span>
          <img class="h-36 w-auto" src="../assets/logos/encom-transp.png" alt="" />
        </a>
      </div>
      <div class="py-16">
        <div class="text-center">
          <p
            class="text-sm font-semibold encom_pink_text uppercase tracking-wide"
          >
            {{ this.status ? this.status : 404 }} error
          </p>
          <h1
            class="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl"
          >
            {{
              this.status == 404
                ? "Page not found."
                : this.status == 401
                ? "Unauthorized Action."
                : "Oups, something wrong happened."
            }}
          </h1>
          <p class="mt-2 text-base text-gray-500">
            {{
              this.status == 404
                ? "Sorry, we couldn’t find the page you’re looking for."
                : this.status == 401
                ? "You wad logged in with an unauthorized user."
                : ""
            }}
          </p>
          <p class="mt-2 text-base text-gray-500" v-show="this.msg">
            Error message: <b>{{ this.msg }}</b>
          </p>
          <div class="mt-6" v-if="this.status == 404">
            <router-link to="/" class="text-base font-medium primary_text"
              >Go back home<span aria-hidden="true"> &rarr;</span></router-link
            >
          </div>
          <div class="mt-6" v-else-if="this.status == 401">
            <a
              href="javascript:void(0)"
              @click.prevent="signOut"
              class="text-base font-medium primary_text"
              >Log out<span aria-hidden="true"> &rarr;</span></a
            >
          </div>
        </div>
      </div>
    </main>
    <!-- <footer class="flex-shrink-0 max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
      <nav class="flex justify-center space-x-4">
        <a
          href="#"
          class="text-sm font-medium text-gray-500 hover:text-gray-600"
          >Contact Support</a
        >
        <span
          class="inline-block border-l border-gray-300"
          aria-hidden="true"
        />
        <a
          href="#"
          class="text-sm font-medium text-gray-500 hover:text-gray-600"
          >Status</a
        >
        <span
          class="inline-block border-l border-gray-300"
          aria-hidden="true"
        />
        <a
          href="#"
          class="text-sm font-medium text-gray-500 hover:text-gray-600"
          >Twitter</a
        >
      </nav>
    </footer> -->
  </div>
</template>

<script>
export default {
  name: "Error404",
  props: ["msg", "status"],
  methods: {
    signOut() {
      localStorage.removeItem("token");
      localStorage.removeItem("adminToken");
      localStorage.removeItem("avatar");
      localStorage.removeItem("account");
      this.$store.dispatch("user", {});
      this.$store.dispatch("adminToken", "");
      this.$store.dispatch("token", "");
      this.$store.dispatch("userAvatar", "");
      this.$store.dispatch("account", "");
      this.$router.push("/login");
      location.reload();
    },
  },
};
</script>

<style></style>
